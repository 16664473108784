import Logo from "components/Logo";

export default function Page404() {
  return <div className="flex flex-col gap-0 w-full">
    <div className="px-2 py-1 fixed"><Logo width={100} height={100} /></div>
    <div className="h-screen flex flex-col gap-2 items-center justify-center">
      <h1 className="text-3xl text-secondary">Страница не найдена</h1>
    </div>
  </div>
}
